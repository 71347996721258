import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Governance = () => {
    return (
        <Layout>
            <Seo title="Governance" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Governance</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../images/other/documents2.jpg"
                                    aspectRatio={16 / 8}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <p>
                                        The Board of Directors are the custodians of good corporate governance. They along with the
                                        Executive Management, aim for a controlled and profitable development with long-term value
                                        creation through well-founded governance principles, operational procedures and risk
                                        management.
                                    </p>
                                    <p>
                                        We believe that our established practices in the area of corporate governance are in line with
                                        the spirit of International standards and provide adequate protection to our shareholders. In
                                        this respect, we have voluntarily adopted a number of exacting practices, that are being
                                        implemented, such as;
                                    </p>
                                    <div className="mb-3">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg ">
                                                (i) having a majority of independent directors,
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                (ii) establishing an audit committee, a compensation committee & a nominating &
                                                corporate governance committee,
                                            </li>
                                            <li className="list-group-item gray-bg"> (iii) adopting a Code of Ethics.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Board Committees</h1>
                    </div>
                </div>
                <div className="row mb-2 ">
                    <div className="col">
                        <h2>Audit Committee</h2>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/petershaerf.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Peter Shaerf</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/michellebockman.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Michelle Bockmann</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/elisabethturnbull.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Elizabeth Turnbull</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2 ">
                    <div className="col">
                        <h2>Renumeration Committee</h2>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/leliakonyn.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Lelia Konyn</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/muditpaliwal.png"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Mudit Paliwal</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/petershaerf.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Peter Shaerf</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2 ">
                    <div className="col">
                        <h2>Governance Committee</h2>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/elisabethturnbull.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Elizabeth Turnbull</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/petershaerf.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Peter Shaerf</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/michellebockman.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Michelle Bockmann</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Kappa Delta Enrichment Foundation</h1>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage aspectRatio={1} className="card-img-top " src="../images/management/aish.jpg" alt="aish" />
                            <div className="card-body">
                                <h5 className="card-title">Dr. Aishwarya Paliwal</h5>
                                <p>Chairperson</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage aspectRatio={1} className="card-img-top " src="../images/management/das.jpg" alt="das" />
                            <div className="card-body">
                                <h5 className="card-title">Sumanta Das</h5>
                                <p>Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage aspectRatio={1} className="card-img-top " src="../images/employees/ajay.jpg" alt="ajay" />
                            <div className="card-body">
                                <h5 className="card-title">Ajay Jain</h5>
                                <p>Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Governance
